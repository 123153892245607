import React from "react";
import {
  Wrapper,
  CardWrapper,
  InfosWrapper,
  DateText,
  TitleLink,
  TitleText,
  PlaceText,
  DescriptionText,
  Spacer,
  PhotoWrapper,
  LineWrapper,
  ByWrapper
} from "./EventCardExpo.module.css";

const EventCardExpo = ({
  date,
  title,
  link,
  place,
  description,
  description2,
  photo,
  index,
  lenght,
  artist,
}) => {

  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={InfosWrapper}>
          <h4 className={PlaceText}>{place}</h4>
          <p className={DateText}>{date}</p>
          <a className={TitleLink} href={link}>
            {artist ?
            <h3 className={TitleText}>{title} <b className={ByWrapper}> {artist}</b></h3>
            : <h3 className={TitleText}>{title}</h3>
          }
          </a>
          {/* <h4 className={PlaceText}>{place}</h4> */}
          <p className={DescriptionText}>{description}</p>
          <br />
          <p className={DescriptionText}>{description2}</p>
        </div>
        <div className={Spacer}></div>
        <div className={PhotoWrapper}>
          <a href={link}>
            <img src={photo} alt=""/>
          </a>
        </div>
      </div>
      {/* {index + 1 < lenght ? <div className={LineWrapper} style={{marginBottom: "20px"}}></div> : null} */}
    </div>
  );
};

export default EventCardExpo;
