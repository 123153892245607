// extracted by mini-css-extract-plugin
export var ByWrapper = "EventCardExpo-module--ByWrapper--wn0ee";
export var CardWrapper = "EventCardExpo-module--CardWrapper--VT019";
export var DateText = "EventCardExpo-module--DateText--bps1W";
export var DescriptionText = "EventCardExpo-module--DescriptionText--6CI7A";
export var InfosWrapper = "EventCardExpo-module--InfosWrapper--EyroZ";
export var LineWrapper = "EventCardExpo-module--LineWrapper--ihbjc";
export var PhotoWrapper = "EventCardExpo-module--PhotoWrapper--Vmdk4";
export var PlaceText = "EventCardExpo-module--PlaceText--oNqRy";
export var Spacer = "EventCardExpo-module--Spacer--EY7yx";
export var TitleLink = "EventCardExpo-module--TitleLink--8stir";
export var TitleText = "EventCardExpo-module--TitleText--6deTY";
export var Wrapper = "EventCardExpo-module--Wrapper--9E1Hx";